import React from "react";
import styles from "./style.module.css";
import { Link, useLocation } from "react-router-dom";

interface PaginationProps {
  count: number;
  handlePageChange: (page: number) => void;
  currentPage: number;
  itemsPerPage: number;
  loadMore?: boolean;
  loader?: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  count,
  handlePageChange,
  currentPage,
  itemsPerPage,
  loadMore,
  loader,
}) => {
  const totalPages = Math.ceil(count / itemsPerPage) || 1;
  const location = useLocation();
  const EsimPage = location.pathname;

  // Ensure currentPage stays within bounds
  const validCurrentPage = Math.max(1, Math.min(currentPage, totalPages));

  // Generate page numbers
  const pages = Array.from({ length: totalPages }, (_, index) => index + 1);

  if (totalPages <= 1) return null; // Hide pagination if only one page exists

  return loadMore ? (
    <div className={styles.pagination}>
      {pages.map((page) => {
        if (page === totalPages && validCurrentPage === page) {
          return (
            <a
              key={page}
              className={validCurrentPage !== page ? styles.active : ""}
              onClick={() => !loader && handlePageChange(1)}
            >
              {loader ? "Loading..." : "Load Less"}
            </a>
          );
        } else if (page === validCurrentPage + 1) {
          return null
          //  (
          //   // Hide "Load More" if user is on /esim-list page
          //   EsimPage !== "/esim-list" && (
          //     <Link
          //       key={page}
          //       to="/esim-list"
          //       className={validCurrentPage === page ? styles.active : ""}
          //     >
          //       {loader ? "Loading..." : "Load More"}
          //     </Link>
          //   )
          // );
        }
        return null;
      })}
    </div>
  ) : (
    <div className={styles.pagination}>
      {totalPages > 5 && validCurrentPage > 1 && (
        <a className={styles.dots} onClick={() => handlePageChange(validCurrentPage - 1)}>
          &lt;
        </a>
      )}

      <>
        {validCurrentPage > 2 && (
          <a className={styles.dots} onClick={() => handlePageChange(1)}>
            1
          </a>
        )}
        {validCurrentPage > 3 && <a className={styles.dots}>...</a>}
      </>

      {pages.map((page) =>
        page === validCurrentPage || page === validCurrentPage - 1 || page === validCurrentPage + 1 ? (
          <a
            key={page}
            className={validCurrentPage === page ? styles.active : ""}
            onClick={() => page !== validCurrentPage && handlePageChange(page)}
          >
            {page}
          </a>
        ) : null
      )}

      <>
        {validCurrentPage < totalPages - 2 && <a className={styles.dots}>...</a>}
        {validCurrentPage < totalPages - 1 && (
          <a className={styles.dots} onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </a>
        )}
      </>

      {totalPages > 5 && validCurrentPage < totalPages && (
        <a className={styles.dots} onClick={() => handlePageChange(validCurrentPage + 1)}>
          &gt;
        </a>
      )}
    </div>
  );
};

export default Pagination;
